import Vue from 'vue'

const displayBRL = {
  install (Vue) {
    Vue.prototype.$displayBRL = function (value) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }
  }
}

export default displayBRL

Vue.use(displayBRL)
