<template>
  <div id="app">
    <base-spinner/>
    <layout-navigation v-if="visible"/>
    <router-view @hide="hideSidebar" @show="showSidebar" :appUsers="allUsers"/>
  </div>
</template>

<script>
import BaseSpinner from './components/global/BaseSpinner'
import LayoutNavigation from './components/layout/LayoutNavigation.vue'

export default {
  name: 'App',
  components: {
    BaseSpinner,
    LayoutNavigation
  },
  data: () => ({
    visible: false,
    allUsers: []
  }),
  mounted () {
    document.body.style.backgroundColor = 'var(--background-gray)'
    if (window.screen.width <= 1000 && this.$route.name !== 'index') {
      document.body.style.zoom = '54%'
      document.body.style.msTransform = '54%'
      document.body.style.transform = '54%'
    } else if (window.screen.width <= 1100 && this.$route.name !== 'index') {
      document.body.style.zoom = '59.5%'
      document.body.style.msTransform = '59.5%'
      document.body.style.transform = '59.5%'
    } else if (window.screen.width <= 1200 && this.$route.name !== 'index') {
      document.body.style.zoom = '65%'
      document.body.style.msTransform = '65%'
      document.body.style.transform = '65%'
    } else if (window.screen.width <= 1300 && this.$route.name !== 'index') {
      document.body.style.zoom = '70%'
      document.body.style.msTransform = '70%'
      document.body.style.transform = '70%'
    } else if (window.screen.width <= 1400 && this.$route.name !== 'index') {
      document.body.style.zoom = '75%'
      document.body.style.msTransform = '75%'
      document.body.style.transform = '75%'
    } else if (window.screen.width <= 1500 && this.$route.name !== 'index') {
      document.body.style.zoom = '80%'
      document.body.style.msTransform = '80%'
      document.body.style.transform = '80%'
    } else if (window.screen.width <= 1600 && this.$route.name !== 'index') {
      document.body.style.zoom = '86%'
      document.body.style.msTransform = '86%'
      document.body.style.transform = '86%'
    } else if (window.screen.width <= 1700 && this.$route.name !== 'index') {
      document.body.style.zoom = '90%'
      document.body.style.msTransform = '90%'
      document.body.style.transform = '90%'
    } else if (window.screen.width <= 1800 && this.$route.name !== 'index') {
      document.body.style.zoom = '95%'
      document.body.style.msTransform = '95%'
      document.body.style.transform = '95%'
    }
  },
  methods: {
    hideSidebar () {
      this.visible = false
    },
    showSidebar () {
      this.visible = true
    },
    // async getUsers () {
    //   if (this.$route.name !== 'login') {
    //     try {
    //       await this.$firebase.database().ref('users').orderByChild('affiliateId').equalTo(window.uid).on('value', snapshot => {
    //         let values = snapshot.val()
    //         values = Object.keys(values).map(i => values[i])
    //         this.allUsers = values
    //       })
    //     } catch (error) {
    //       this.$toast.error(`Erro ao carregar tabela, recarrege a página por gentileza.`)
    //     }
    //   }
    // },
  }
}
</script>

<style lang="scss">
#app{
  min-height: 100vh !important;
  height: 100vh !important;
  background-color: var(--background-gray);
}
.Vue-Toastification__toast--default.my-custom-toast-class {
    background-color: #0dce9a !important;
}
</style>
